import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import 'rxjs/add/operator/filter';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
    
public routerPublic;

  constructor( public location: Location, private router: Router) {

    this.routerPublic = router;

  }

  ngOnInit() {
      
  }
}
