import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {NotificationsComponent} from '../notifications/notifications.component';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const CLIENT_KEY = 'cliente-selected';
const CLIENT_OPTIONS_KEY = 'cliente-options-';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    constructor() {
    }

    signOut(): void {
        window.sessionStorage.clear();
    }

    public saveToken(token: string): void {
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        return sessionStorage.getItem(TOKEN_KEY);
    }

    public saveRefreshToken(token: string): void {
        window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
        window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
    }

    public getRefreshToken(): string | null {
        return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
    }

    public saveUser(user, notifications: NotificationsComponent): void {
        window.sessionStorage.removeItem(USER_KEY);
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    public getUser(): any {
        return JSON.parse(sessionStorage.getItem(USER_KEY));
    }

    public saveClient(client): void {
        window.sessionStorage.removeItem(CLIENT_KEY);
        window.sessionStorage.setItem(CLIENT_KEY, JSON.stringify(client));
    }

    public saveClientOptions(options): void {
        window.sessionStorage.removeItem(CLIENT_OPTIONS_KEY);
        window.sessionStorage.setItem(CLIENT_OPTIONS_KEY, JSON.stringify(options));
    }

    public getClient(): any {
        return JSON.parse(sessionStorage.getItem(CLIENT_KEY));
    }

    public getClientOptions(): any {
        return JSON.parse(sessionStorage.getItem(CLIENT_OPTIONS_KEY));
    }
}
