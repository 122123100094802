import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import {catchError, map, switchMap } from 'rxjs/operators';
import {merge, of as observableOf, throwError} from 'rxjs';
import {NotificationsComponent} from '../notifications/notifications.component';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./login.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  form: any = {};
  isResetFailed = false;
  errorMessage = '';
  token: String;
  environment = environment;

  public ts;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, public notifications: NotificationsComponent,private route: ActivatedRoute, private _router: Router, public translateService: TranslateService) {
    this.ts = translateService;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token =  params['token'];
    });
  }

  onSubmit(): void {


    if(this.form.password1 != this.form.password2){
      this.notifications.showDangerNotification( this.getTs().instant('error.password'));
    } else{

      this.authService.resetPassword(this.token,this.form.password1).pipe(
        catchError(err => {
            return throwError('Error reseteo');
        })
      ).subscribe(next => {
          this._router.navigate(['/login']).then(()=>{
            this.notifications.showSuccessNotification(this.getTs().instant('success.paswordChanged'));
          });
      }, err => {
          this.notifications.showDangerNotification(this.getTs().instant('error.resetPassword'))
          return observableOf([]);
      });

    }
  }

  getTs(): TranslateService {
    return this.ts;
  }

}
