import {NgModule} from '@angular/core';
import {NotificationsComponent} from './notifications.component';

@NgModule({
    declarations: [
        NotificationsComponent
    ],
    exports: [
        NotificationsComponent
    ],
    providers: [
        NotificationsComponent
    ]
})
export class NotificationsModule {
}
