import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PantasmaListApi} from './pantasma-api.interface';
import {EventEmitter, Injectable, ViewChild} from '@angular/core';
import {NotificationsComponent} from '../../notifications/notifications.component';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {TokenStorageService} from 'app/_services/token-storage.service';
import {MovimientoComponent} from "../detail/movimiento.component";
import {ConsultaComponent} from "../detail/consulta.component";
import { LongDateFormatKey } from 'moment';
import {ConsultaInformeParteComponent} from "../detail/consultaInformeParte.component";


const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};
const AUTH_API = environment.serverUrl + '/api/auth/';


@Injectable()
export class PantasmaHttpClient {

    @ViewChild(NotificationsComponent) notifications: NotificationsComponent;


    urlBase = environment.serverUrl + '/api/private/';
    public change: EventEmitter<string> = new EventEmitter<string>();
    public changeDetail: EventEmitter<string> = new EventEmitter<string>();
    public changeRelationDetail: EventEmitter<string> = new EventEmitter<string>();
    public comboLoaded: EventEmitter<string> = new EventEmitter<string>();
    public otherChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(private _httpClient: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    refreshToken(token: string): Observable<any> {
        return this._httpClient.post(AUTH_API + 'refresh-token', {
            refreshToken: token
        }, httpOptions);
    }

    getList(sort: string, order: string, page: any, requestMapping: string, pageSize: any, filters: any, filterFields: any, filtersFrom: any, filtersTo: any, idById?: any, parentMapping?: any): Observable<PantasmaListApi> {

        var self = this;
        var filter = this.getFilters(filters, filterFields);
        var dates = this.getDates(filtersFrom, filtersTo, filterFields);

        if (idById != null) {
            filter[parentMapping + "Id"] = idById;
        }

        var query = {
            item: filter,
            dates: dates
        };

        const href = this.urlBase + requestMapping + '/find';
        const requestUrl =
            `${href}?order=${sort}&direction=${order}&page=${page}&size=${pageSize}`;

        setTimeout(function () {

            self.refreshToken(self.tokenStorageService.getRefreshToken()).subscribe(next => {
                self.tokenStorageService.saveToken(next.accessToken);
            }, err => {

            });

        }, 2);
        // const headers = new HttpHeaders({'Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods':'GET,POST,OPTIONS,DELETE,PUT'});
        return this._httpClient.post<PantasmaListApi>(requestUrl, query, httpOptions);
    }


    getDates(filtersFrom: string[], filtersTo: string[], filterFields: string[]): any {

        var list = [];

        filterFields.forEach((element, index) => {

            var json = {};
            if (filtersFrom[index] != '' && filtersFrom[index] != null) {


                json = {
                    from: filtersFrom[index],
                    field: element
                };

                list.push(json);
            }

            if (filtersTo[index] != '' && filtersTo[index] != null) {


                json = {
                    to: filtersTo[index],
                    field: element
                };

                list.push(json);
            }

        });

        return list;

    }

    getFilters(filters: string[], filterFields: string[]): any {

        var json = {};

        filterFields.forEach((element, index) => {
            if (filters[index] != '') {
                if (element.indexOf('.') != -1) {
                    if (json[element.split(".")[0]] == undefined) {
                        json[element.split(".")[0]] = {};
                    }
                    json[element.split(".")[0]][element.split(".")[1]] = filters[index];
                } else {
                    json[element] = filters[index];
                }

            }
        });

        return json;

    }

    deleteItem(id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id;
        return this._httpClient.delete(requestUrl).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    getItem(id: number, requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/' + id;
        return this._httpClient.get(requestUrl)
    }

    createItem(item, requestMapping: string, main: boolean, other: boolean = false) {


        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/';
        return this._httpClient.post(requestUrl, item, httpOptions).pipe(
            map(result => {

                if (!other) {
                    if (main) {
                        self.changeDetail.emit(result['id']);
                    } else {
                        self.changeRelationDetail.emit(result['id']);
                    }
                } else {
                    return result;
                }

            })
        );

    }

    updateItem(item, requestMapping: string, main: boolean) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + item['id'] + '/';
        return this._httpClient.put(requestUrl, item, httpOptions).pipe(
            map(_ => {
                if (main) {
                    self.changeDetail.emit(item['id']);
                } else {
                    self.changeRelationDetail.emit(item['id']);
                }

            })
        );
    }

    updateJSON(item, requestMapping: string, main: boolean) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/updateJSON/' + item['id'] + '/';
        return this._httpClient.put(requestUrl, item.properties, httpOptions).pipe(
            map(result => {
                return result;
            })
        );
    }

    getMetadata(requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/metadata/';
        return this._httpClient.get(requestUrl);
    }

    export(format: String, requestMapping: string, columns: string[], type: string, ids: number[], idById?: any, parentMapping?: any, userCode?: String) {
        const requestUrl = this.urlBase + requestMapping + '/export/';
        var filter = {};

        if (idById != null) {
            filter[parentMapping + "Id"] = idById;
        }

        var json = {
            extension: format,
            filter: filter,
            fields: columns,
            ids: ids,
            code: userCode
        }

        return this._httpClient.post(requestUrl, json, {responseType: 'blob'});

    }

    activate(id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/activate';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    changeState(state: string, id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/changeState/' + id + '/' + state;
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit("change");
                return result;
            })
        );
    }

    changeStateFormulario(state: string, id: number, formulario: string, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/changeStateFormulario/' + id + '/' + formulario + '/' + state;
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.changeDetail.emit(id + "");
            })
        );
    }

    citar(id: number, requestMapping: string, date: Date, hora: string) {
        var self = this;
        if (hora == undefined || hora == null || hora == '') {
            hora = "vacio";
        }
        const requestUrl = this.urlBase + requestMapping + '/citar/' + id + '/' + date + '/' + hora;
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit("change");
                return result;
            })
        );
    }

    changeStateMultiple(state: string, ids: number[], requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/changeState/' + state;
        var json = {
            ids: ids
        }
        return this._httpClient.put(requestUrl, json).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    deactivate(id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/deactivate';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    clone(id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/clone';
        return this._httpClient.put(requestUrl, {}).pipe(
            // catchError(err => {
            //     if ([401,422,  403].indexOf(err.status) !== -1) {
            //         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //         this.tokenStorageService.signOut();
            //     }

            //     const error = err.error.message || err.statusText;
            //     return throwError(error);
            // }),
            map(result => {
                self.change.emit("change");
            })
        );
    }

    getCombo(requestMapping: string): Observable<any> {

        var comboUrlLoad = requestMapping;

        if (requestMapping.startsWith("mantenimiento")) {
            comboUrlLoad = "mantenimiento";
            const requestUrl = this.urlBase + comboUrlLoad + '/comboByTipo/' + requestMapping.replace("mantenimiento", "");
            return this._httpClient.get(requestUrl).pipe(
                map(data => {
                    this.comboLoaded.emit(requestMapping);
                    return data;

                })
            );
        } else {
            const requestUrl = this.urlBase + comboUrlLoad + '/combo';
            return this._httpClient.get(requestUrl).pipe(
                map(data => {
                    this.comboLoaded.emit(requestMapping);
                    return data;

                })
            );
        }


    }

    generarInforme(requestMapping: string, tipoReport: string, idReport: number, id: any) {
        const requestUrl = this.urlBase + requestMapping + '/generarInforme/' + id + '/' + idReport + '/' + tipoReport;
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    download(requestMapping: string, tipoDoc: string, id: number) {
        const requestUrl = this.urlBase + requestMapping + '/download/' + id + '/' + tipoDoc;
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});

    }

    descargarExcelCompleto(requestMapping: string, tipo: string) {
        const requestUrl = this.urlBase + requestMapping + '/descargarExcel';
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    obtenerMensajeInformativo(idCliente: number) {
        const self = this;
        const requestUrl = this.urlBase + 'mensaje/obtener/' + idCliente + '?clave=INFORMATIVO';
        return this._httpClient.get(requestUrl, {}).pipe();
    }

    obtenerVehiculoAsignado(personaId: number) {
        const requestUrl = this.urlBase + 'vehiculo/' + personaId + '/asignado';
        return this._httpClient.get(requestUrl);
    }

    obtenerVehiculo(id: number) {
        const requestUrl = this.urlBase + 'vehiculo/' + id + '/obtener';
        return this._httpClient.get(requestUrl);
    }

    obtenerPersonaActual(userId: number) {
        const requestUrl = this.urlBase + 'persona/' + userId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerUserActual(userId: number) {
        const requestUrl = this.urlBase + 'user/' + userId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerJornadaActual(personaId: number) {
        const requestUrl = this.urlBase + 'jornada/' + personaId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerParteActual(personaId: number) {
        const requestUrl = this.urlBase + 'parte/' + personaId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerDatosUltimoParte(personaId: number) {
        const requestUrl = this.urlBase + 'parte/' + personaId + '/obtenerDatosUltimoParte';
        return this._httpClient.get(requestUrl);
    }

    obtenerTrabajoActual(personaId: number) {
        const requestUrl = this.urlBase + 'trabajo/' + personaId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerActuacionActual(personaId: number) {
        const requestUrl = this.urlBase + 'actuacion/' + personaId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerConductorActual(vehiculoId: number, parteId: number) {
        const requestUrl = this.urlBase + 'conductorParte/' + vehiculoId + '/actual/' + parteId;
        return this._httpClient.get(requestUrl);
    }


    obtenerPausaActual(personaId: number) {
        const requestUrl = this.urlBase + 'pausa/' + personaId + '/actual';
        return this._httpClient.get(requestUrl);
    }

    obtenerMovimientoSalida(personaId: number) {
        const requestUrl = this.urlBase + 'movimiento/' + personaId + '/ultimaSalida';
        return this._httpClient.get(requestUrl);
    }

    obtenerMovimientoLlegada(personaId: number) {
        const requestUrl = this.urlBase + 'movimiento/' + personaId + '/ultimaLlegada';
        return this._httpClient.get(requestUrl);
    }

    obtenerTareasActuacion(actuacionId: number) {
        const requestUrl = this.urlBase + 'tareaActuacion/' + actuacionId + '/tareas';
        return this._httpClient.get(requestUrl);
    }

    obtenerObras(personaId: number) {
        const requestUrl = this.urlBase + 'obra/' + personaId + '/obras';
        return this._httpClient.get(requestUrl);
    }

    obtenerVehiculos(personaId: number) {
        const requestUrl = this.urlBase + 'vehiculo/' + personaId + '/vehiculos';
        return this._httpClient.get(requestUrl);
    }

    obtenerVehiculosLibres() {
        const requestUrl = this.urlBase + 'vehiculo/vehiculosLibres';
        return this._httpClient.get(requestUrl);
    }

    obtenerMaquinarias(clienteId: number) {
        const requestUrl = this.urlBase + 'maquinaria/' + clienteId + '/maquinarias';
        return this._httpClient.get(requestUrl);
    }

    obtenerTarjetas(clienteId: number) {
        const requestUrl = this.urlBase + 'tarjeta/' + clienteId + '/tarjetas';
        return this._httpClient.get(requestUrl);
    }

    obtenerTiposPausa(userId: number) {
        const requestUrl = this.urlBase + 'tipoPausa/' + userId + '/tiposPausa';
        return this._httpClient.get(requestUrl);
    }

    obtenerFirmas(firmaDto: MovimientoComponent) {
        const requestUrl = this.urlBase + 'firmaParte/firmas';
        return this._httpClient.post(requestUrl, firmaDto, {});
    }

    obtenerAveria(personaId: number) {
    const requestUrl = this.urlBase + 'averia/' + personaId + '/averia';
    return this._httpClient.get(requestUrl);
}

    obtenerTareas(personaId: number) {
        const requestUrl = this.urlBase + 'tarea/' + personaId + '/tareas';
        return this._httpClient.get(requestUrl);
    }





    obtenerHerramientasDisponibles(parteId: Number) {
        const requestUrl = this.urlBase + 'herramienta/herramientasDisponibles/' + parteId;
        return this._httpClient.post(requestUrl, {}, {});
    }

    obtenerHerramientasOcupadas(parteId: Number) {
        const requestUrl = this.urlBase + 'herramienta/herramientasOcupadas/' + parteId;
        return this._httpClient.post(requestUrl, {}, {});
    }


    obtenerMaterialesDisponibles(vehiculoId: Number) {
        const requestUrl = this.urlBase + 'stockArticulo/materialesDisponibles/' + vehiculoId;
        return this._httpClient.post(requestUrl, {}, {});
    }

    obtenerMaterialesOcupados(vehiculoId: Number) {
        const requestUrl = this.urlBase + 'stockArticulo/materialesOcupados/' + vehiculoId;
        return this._httpClient.post(requestUrl, {}, {});
    }

    obtenerMaterialesDisponiblesActuacion(vehiculoId: Number, actuacionId: Number) {
        const requestUrl = this.urlBase + 'stockArticulo/materialesDisponibles/' + vehiculoId + '/' + actuacionId;
        return this._httpClient.post(requestUrl, {}, {});
    }

    obtenerMaterialesOcupadosActuacion(vehiculoId: Number, actuacionId: Number) {
        const requestUrl = this.urlBase + 'stockArticulo/materialesOcupados/' + vehiculoId + '/' + actuacionId;
        return this._httpClient.post(requestUrl, {}, {});
    }






    obtenerPartesDisponibles(userId: number) {
        const requestUrl = this.urlBase + 'parte/' + userId + '/disponibles';
        return this._httpClient.get(requestUrl);
    }

    obtenerPersonasParte(parteId: number) {
        const requestUrl = this.urlBase + 'parte/' + parteId + '/personas';
        return this._httpClient.get(requestUrl);
    }

    obtenerPersonaDisponiblesIniciarParte() {
        const requestUrl = this.urlBase + 'persona/disponiblesIniciarParte';
        return this._httpClient.get(requestUrl);
    }

    obtenerPersonaDisponiblesAsociar(parteId: number) {
        const requestUrl = this.urlBase + 'persona/disponiblesAsociar/' + parteId;
        return this._httpClient.get(requestUrl);
    }

    obtenerPersonaDisponiblesDesasociar(parteId: number) {
        const requestUrl = this.urlBase + 'persona/disponiblesDesasociar/' + parteId;
        return this._httpClient.get(requestUrl);
    }

    obtenerMensajeObra(idCliente: number) {
        const requestUrl = this.urlBase + 'mensaje/obtener/' + idCliente + '?clave=INFO_OBRA';
        return this._httpClient.get(requestUrl, {}).pipe();
    }

    obtenerMensajeJornada(idCliente: number) {
        const requestUrl = this.urlBase + 'mensaje/obtener/' + idCliente + '?clave=INFO_JORNADA\n';
        return this._httpClient.get(requestUrl, {}).pipe();
    }

    iniciarJornada(jornada: MovimientoComponent) {
        const requestUrl = this.urlBase +'jornada/iniciar';
        return this._httpClient.post(requestUrl, jornada, {}).pipe();
    }

    finalizarJornada(jornada: MovimientoComponent) {
        const requestUrl = this.urlBase +'jornada/finalizar';
        return this._httpClient.post(requestUrl, jornada, {});
    }

    iniciarObra(obra: MovimientoComponent) {
        const requestUrl = this.urlBase +'trabajo/iniciar';
        return this._httpClient.post(requestUrl, obra, {});
    }

    finalizarObra(obra: MovimientoComponent) {
        const requestUrl = this.urlBase +'trabajo/finalizar?';
        return this._httpClient.post(requestUrl, obra, {});
    }

    iniciarAveria(averia: MovimientoComponent) {
        const requestUrl = this.urlBase +'averia/iniciar';
        return this._httpClient.post(requestUrl, averia, {});
    }

    finalizarAveria(averia: MovimientoComponent) {
        const requestUrl = this.urlBase +'averia/finalizar?';
        return this._httpClient.post(requestUrl, averia, {});
    }

    iniciarActuacion(actuacion: MovimientoComponent) {
        const requestUrl = this.urlBase +'actuacion/iniciar';
        return this._httpClient.post(requestUrl, actuacion, {});
    }

    finalizarActuacion(actuacion: MovimientoComponent) {
        const requestUrl = this.urlBase +'actuacion/finalizar';
        return this._httpClient.post(requestUrl, actuacion, {});
    }

    eliminarTareaActuacion(tareaActuacionId: number){
        const requestUrl = this.urlBase +'tareaActuacion/' + tareaActuacionId + '/eliminar';
        return this._httpClient.post(requestUrl, {}, {});
    }

    crearTarea(tarea: MovimientoComponent) {
        const requestUrl = this.urlBase +'tarea/crear';
        return this._httpClient.post(requestUrl, tarea, {});
    }

    editarTarea(tarea: MovimientoComponent) {
        const requestUrl = this.urlBase +'tarea/editar';
        return this._httpClient.post(requestUrl, tarea, {});
    }

    anadirHerramienta(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'herramienta/anadir';
        return this._httpClient.post(requestUrl, parte, {});
    }

    eliminarHerramientas(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'herramienta/eliminar';
        return this._httpClient.post(requestUrl, parte, {});
    }

    anadirMaterial(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'movimientoMaterialVehiculo/entrada';
        return this._httpClient.post(requestUrl, parte, {});
    }

    eliminarMaterial(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'movimientoMaterialVehiculo/salida';
        return this._httpClient.post(requestUrl, parte, {});
    }

    anadirMaterialActuacion(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'movimientoMaterialVehiculo/entradaActuacion';
        return this._httpClient.post(requestUrl, parte, {});
    }

    eliminarMaterialActuacion(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'movimientoMaterialVehiculo/salidaActuacion';
        return this._httpClient.post(requestUrl, parte, {});
    }

    indicarConductor(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'conductorParte/indicarConductor';
        return this._httpClient.post(requestUrl, parte, {});
    }

    obtenerPasajerosActuales(movimiento: MovimientoComponent) {
        const requestUrl = this.urlBase +'personaMovimiento/obtenerPasajerosActuales';
        return this._httpClient.post(requestUrl, movimiento,  {}).pipe();
    }

    obtenerPersonasLibresPasajeros(movimiento: MovimientoComponent) {
        const requestUrl = this.urlBase +'personaMovimiento/obtenerPersonasLibresPasajeros';
        return this._httpClient.post(requestUrl, movimiento,  {}).pipe();
    }

    anadirPasajeros(movimiento: MovimientoComponent) {
        const requestUrl = this.urlBase +'personaMovimiento/anadirPasajeros';
        return this._httpClient.post(requestUrl, movimiento, {});
    }

    eliminarPasajeros(movimiento: MovimientoComponent) {
        const requestUrl = this.urlBase +'personaMovimiento/eliminarPasajeros';
        return this._httpClient.post(requestUrl, movimiento, {});
    }

    finalizarParte(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'parte/finalizar';
        return this._httpClient.post(requestUrl, parte, {});
    }

    firmarParte(firma: MovimientoComponent) {
        const requestUrl = this.urlBase +'firmaParte/firmar';
        return this._httpClient.post(requestUrl, firma, {});
    }

    asociarseParte(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'parte/asociarse';
        return this._httpClient.post(requestUrl, parte, {});
    }

    asociarPersonaParte(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'parte/asociarPersonas';
        return this._httpClient.post(requestUrl, parte, {});
    }

    desasociarseParte(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'parte/desasociarse';
        return this._httpClient.post(requestUrl, parte, {});
    }

    desasociarPersonasParte(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'parte/desasociarPersonas';
        return this._httpClient.post(requestUrl, parte, {});
    }

    iniciarPausa(pausa: MovimientoComponent) {
        const requestUrl = this.urlBase +'pausa/iniciar';
        return this._httpClient.post(requestUrl, pausa, {});
    }

    finalizarPausa(pausa: MovimientoComponent) {
        const requestUrl = this.urlBase +'pausa/finalizar';
        return this._httpClient.post(requestUrl, pausa, {});
    }

    llegada(movimiento: MovimientoComponent) {
        const requestUrl = this.urlBase +'movimiento/llegada';
        return this._httpClient.post(requestUrl, movimiento, {}).pipe();
    }

    salida(movimiento: MovimientoComponent) {
        const requestUrl = this.urlBase +'movimiento/salida';
        return this._httpClient.post(requestUrl, movimiento, {}).pipe();
    }

    repostaje(repostaje: MovimientoComponent) {
        const requestUrl = this.urlBase +'repostaje/realizarRepostaje';
        return this._httpClient.post(requestUrl, repostaje, {}).pipe();
    }

    seleccionarVehiculo(parte: MovimientoComponent) {
            const requestUrl = this.urlBase +'vehiculo/seleccionarVehiculo';
        return this._httpClient.post(requestUrl, parte, {}).pipe();
    }

    repostajeMaquinaria(repostaje: MovimientoComponent) {
        const requestUrl = this.urlBase +'repostajeMaquinaria/realizarRepostajeMaquinaria';
        return this._httpClient.post(requestUrl, repostaje, {}).pipe();
    }

    liberarVehiculo(parte: MovimientoComponent) {
        const requestUrl = this.urlBase +'vehiculo/liberar';
        return this._httpClient.post(requestUrl, parte, {});
    }

    asociarseVehiculo(vehiculoId: number, personaId: number) {
        const requestUrl = this.urlBase +'vehiculo/' + vehiculoId + '/asociar/' + personaId;
        return this._httpClient.post(requestUrl, {}, {});
    }

    obtenerClientes() {
        const requestUrl = this.urlBase + 'cliente/clientes';
        return this._httpClient.get(requestUrl);
    }

    obtenerPersonas() {
        const requestUrl = this.urlBase + 'persona/personas';
        return this._httpClient.get(requestUrl);
    }

    descargarParteFirma(parteFirmaId: number) {
        const requestUrl = this.urlBase + 'firmaParte/' + parteFirmaId + '/descargar';
        return this._httpClient.post(requestUrl, {}, {responseType: 'blob'});
    }

    descargarInforme(consulta: ConsultaComponent) {
        const requestUrl = this.urlBase + 'jornada/descargarInformeJornada';
        return this._httpClient.post(requestUrl, consulta, {responseType: 'blob'});
    }

    descargarInformeParte(consulta: ConsultaInformeParteComponent) {
        const requestUrl = this.urlBase + 'parte/descargarInformeParte';
        return this._httpClient.post(requestUrl, consulta, {responseType: 'blob'});
    }

    obtenerPartesInforme() {
        const requestUrl = this.urlBase + 'parte/partesInforme';
        return this._httpClient.get(requestUrl);
    }

    obtenerObrasInforme() {
        const requestUrl = this.urlBase + 'obra/obrasInforme';
        return this._httpClient.get(requestUrl);
    }

    obtenerVehiculosInforme() {
        const requestUrl = this.urlBase + 'vehiculo/vehiculosInforme';
        return this._httpClient.get(requestUrl);
    }

    activar(id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/activar';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    desactivar(id: number, requestMapping: string) {
        var self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/desactivar';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit("change");
            })
        );
    }

    ejecutar(id: number, requestMapping: string) {
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/ejecutar' ;
        return this._httpClient.get(requestUrl,{}).pipe();
    }

    lock(id: number, requestMapping: string) {
        const self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/lock';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit('change');
            })
        );
    }

    unlock(id: number, requestMapping: string) {
        const self = this;
        const requestUrl = this.urlBase + requestMapping + '/' + id + '/unlock';
        return this._httpClient.put(requestUrl, {}).pipe(
            map(result => {
                self.change.emit('change');
            })
        );
    }
}
