import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoginDialogData} from './login-dialog.data';
import {TranslateService} from '@ngx-translate/core';
import {TokenStorageService} from 'app/_services/token-storage.service';
import {AuthService} from 'app/_services/auth.service';
import {NotificationsComponent} from '../notifications/notifications.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'login-dialog',
    templateUrl: './login-dialog.component.html',
    styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent {

    isMFA = false;
    pwd = null;
    _dialogRef: MatDialogRef<LoginDialogComponent>;
    username:String;
    password: String;
    code: String;
    errorMessage: String="";
    isLoginFailed: boolean = false;
    _data: LoginDialogData;
    public ts;

    constructor(
        public dialogRef: MatDialogRef<LoginDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LoginDialogData,
        private translate: TranslateService, private authService: AuthService,
        private tokenStorage: TokenStorageService,
        public notifications: NotificationsComponent) {

            this._dialogRef = dialogRef;
            this.ts = translate;
            this._data = data;
            this.username = tokenStorage.getUser().username;

        }

    onLogin(): void {

        var self=this;
        if(!this.isMFA){
            this.authService.login({
                'username': this.username,
                'password': this.password
            }).subscribe(
                data => {
    
                    if(data.mfa){
                        this.isLoginFailed = false;
                        this.isMFA=true;
                        this.pwd = this.password;
                        this.tokenStorage.saveUser(data, this.notifications);
                        
                        //this.roles = this.tokenStorage.getUser().roles;
                        if (this.tokenStorage.getUser().language != null) {
                            this.translate.use(this.tokenStorage.getUser().language);
                        }
                    }
                    else{
                        this.tokenStorage.saveToken(data.token);
                        this.tokenStorage.saveRefreshToken(data.refreshToken);
                        this.tokenStorage.saveUser(data, this.notifications);
                        
                        // this.roles = this.tokenStorage.getUser().roles;
                        if (this.tokenStorage.getUser().language != null) {
                            this.translate.use(this.tokenStorage.getUser().language);
                        }
                        
                        this._dialogRef.close(true);
                        
                    }
                    
                },
                err => {
                    if (err.error === 'User credentials have expired') {
                        this.errorMessage = this.getTs().instant('error.credentialsExpired');
                       
                    } else {
                        if (err.error === 'User account is locked') {
                            this.errorMessage = this.getTs().instant('error.accountLocked');
                        } else if (err.error === 'Bad credentials') {
                            this.errorMessage = this.getTs().instant('error.badCredentials');
                        } else {
                            this.errorMessage = this.getTs().instant('error.logginError');
                        }
                        this.isLoginFailed = true;
                       
                    }
                }
            );
        }
       else{
        this.authService.verify({
            'username': this.username,
            'code': this.code
        }, this.pwd).subscribe(
            data => {
               
                this.pwd=null;
                this.tokenStorage.saveToken(data.token);
                this.tokenStorage.saveRefreshToken(data.refreshToken);
                this._dialogRef.close(true);
             
                
            },
            err => {
                if (err.error === 'User credentials have expired') {
                    this.errorMessage = this.getTs().instant('error.credentialsExpired');
                } else {
                    if (err.error === 'User account is locked') {
                        this.errorMessage = this.getTs().instant('error.accountLocked');
                    } else if (err.error === 'Bad credentials') {
                        this.errorMessage = this.getTs().instant('error.badCredentials');
                    } else if (err.error === 'Code Wrong') {
                        this.errorMessage = this.getTs().instant('error.badCredentialsCode');
                    }else {
                        this.errorMessage = this.getTs().instant('error.logginError');
                    }
                    this.isLoginFailed = true;
                   
                }
            }
        );
       }

       
    }

    getTs() {
        return this.ts;
    }

}
