import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TokenStorageService} from '../../_services/token-storage.service';
import {Role} from '../../_models/role';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    class: string;
    authenticate?: Boolean;
    roles?: Role[];
    separatorAfter?: boolean,
    submenus?: RouteInfo[],
    modulos?: string[]
}

export const ROUTES: RouteInfo[] = [

    { path: '/dashboard', title: 'Inicio',  icon: 'dashboard', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor, Role.Tecnico], modulos: ['trabajo']},

    

    { path: '', title: 'Partes',  icon: 'edit_calendar', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['trabajo'],
        submenus: [
            { path: '/parte', title: 'Partes de trabajo',  icon: 'edit_calendar', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['trabajo'] },
            { path: '/averia', title: 'Averías',  icon: 'handyman', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['trabajo'] },
            { path: '/obra', title: 'Obras',  icon: 'handyman', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['trabajo']},
            { path: '/tarea', title: 'Tareas',  icon: 'domain', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/informesParte', title: 'Informes', icon: 'view_kanban', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['informes']}
        ]
    },

    { path: '', title: 'Jornada',  icon: 'groups', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['plantilla'],
        submenus: [            
            { path: '/jornada', title: 'Registro de jornada',  icon: 'edit_calendar', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['trabajo']},           
            // { path: '/equipo', title: 'Equipos',  icon: 'diversity_3', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['plantilla']},
            { path: '/persona', title: 'Trabajadores',  icon: 'face', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['plantilla']},
            { path: '/pausa', title: 'Invervalos no laborales',  icon: 'pause_circle', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['pausa']},
            { path: '/tipoPausa', title: 'Tipos de intervalos no laborales',  icon: 'pause_circle', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['pausa']},
            { path: '/informesJornada', title: 'Informes',  icon: 'description', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['informes']}
        ]
    },    

    { path: '', title: 'Pluses',  icon: 'euro', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['salarial'],
        submenus: [
            { path: '/plus', title: 'Tipos de pluses',  icon: 'control_point_duplicate', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['salarial']},
        ]
    },

    
    
    { path: '', title: 'Equipos',  icon: 'handyman', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['trabajo'],
        submenus: [
            { path: '/vehiculo', title: 'Vehiculos',  icon: 'directions_car', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/maquinaria', title: 'Maquinaria',  icon: 'forklift', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/tipoHerramienta', title: 'Tipos de herramienta',  icon: 'domain', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/herramienta', title: 'Herramientas',  icon: 'handyman', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/repostajeMaquinaria', title: 'Repostajes de maquinaria',  icon: 'handyman', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/material', title: 'Materiales',  icon: 'inventory', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
        ]
    },

    { path: '', title: 'Administración',  icon: 'admin_panel_settings', class: '', authenticate: true, roles: [Role.Admin, Role.Gestor], modulos: ['administracion'],
        submenus: [

            { path: '/centro', title: 'Centros de trabajo',  icon: 'domain', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/calendario', title: 'Calendarios',  icon: 'calendar_month', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/mensaje', title: 'Mensajes',  icon: 'chat', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/alerta', title: 'Alertas',  icon: 'warning', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']},
            { path: '/tarjeta', title: 'Tarjetas Solred',  icon: 'credit_card', class: '', authenticate: true,  roles: [Role.Admin, Role.Gestor], modulos: ['administracion']}
        ]
    },

    {path: '/user', title: 'Usuarios', icon: 'group', class: '', authenticate: true, roles: [Role.Admin]}


    
            // {path: '/cliente', title: 'Clientes', icon: 'support_agent', class: '', authenticate: true, roles: [Role.Admin]}

];
//
// export const ROUTES_NO_LDAP: RouteInfo[] = [
//   { path: '/user-profile', title: 'User Profile',  icon: 'person', class: '', authenticate: true }
// ];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    environment = environment;
    currentRout: any;

    constructor(private tokenStorageService: TokenStorageService, private domSanitizer: DomSanitizer, private router: ActivatedRoute,) {
    }

    ngOnInit() {

        this.menuItems = ROUTES.filter(menuItem => menuItem);
        // this.menuItems.push(ROUTES_NO_LDAP.filter(menuItem => menuItem)[0]);
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };


    logout(): void {
        this.tokenStorageService.signOut();
        window.location.reload();
    }

    authenticationCheckBoolean(): boolean {
        return this.tokenStorageService.getToken() != null;
    }

    authenticationCheck(r: RouteInfo): boolean {

        if (r.authenticate != null) {
            if (r.authenticate) {


                if (this.tokenStorageService.getToken() != null) {
                    if (r.roles != null) {

                        let counter = 0;
                        for (var i = 0; i < r.roles.length; i++) {
                            if (this.tokenStorageService.getUser().roles.includes(r.roles[i])) {
                                counter++;
                            }
                        }

                        return counter > 0;


                    } else {
                        return true;
                    }
                } else {
                    return false;

                }


            } else {
                return this.tokenStorageService.getToken() == null;
            }

        } else {
            return true;
        }


    }

    isActivatedModule(menuItem) {

        return environment.modulos == undefined || menuItem.modulos == undefined || menuItem.modulos.some(r => environment.modulos.includes(r))

    }

}
