import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';

import {TokenStorageService} from '../_services/token-storage.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, switchMap, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {NotificationsComponent} from "../notifications/notifications.component";
import {TranslateService} from "@ngx-translate/core";
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from 'app/login/login-dialog.component';
import { map } from 'jquery';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private handled = false;
    private isRefreshingToken = false;
    private _dialogRef: any;

    constructor(private tokenStorage: TokenStorageService, public router: Router, public _dialog: MatDialog) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = this.addToken(req);
        var self = this;

        return next.handle(authReq).pipe(
            catchError((error) => {
                
                if ([401, 403].indexOf(error.status) !== -1) {
                    if (!self.isRefreshingToken) {
                        self.isRefreshingToken = true;

                        if (error instanceof HttpErrorResponse ) {

                            if(error.url.indexOf("signin") != -1){
                                this.handled = false;
                            }
                            else if (error.error instanceof ErrorEvent) {
                                console.error("Error Event");
                            } else {
                                switch (error.status) {
                                    case 401:      //login
                                        
                                        self.handled = true;
                                        break;
                                }
                            }
                        }
                    
                    
                        if (self.handled!=null && self.handled) {
                            
                            this._dialogRef = this._dialog.open(LoginDialogComponent);
                            return this._dialogRef.afterClosed().pipe(
                                switchMap(() => {
                                    this.isRefreshingToken = false;
                                    return next.handle(this.addToken(req))
                                })
                            );

                        } else {
                            return throwError(error);
                        }
                    }
                    else {
                        if(error.url.indexOf("signin") == -1){

                            if(this._dialogRef != undefined){
                                return this._dialogRef.afterClosed().pipe(
                                    switchMap(() => {
                                        return next.handle(this.addToken(req))
                                    })
                                );
                            }
                            else{

                                return next.handle(this.addToken(req))
                            }
                        }
                        else{
                            return throwError(error);
                        }
                    }
                }
                else{
                      return throwError(error.error);
                }
        
            })
        ) as Observable<HttpEvent<any>>;
    }

    showLoginModal() {

        var self = this;
        return new Observable<any>((observer) => {
            let dialogRef = self._dialog.open(LoginDialogComponent, {
               
            });
            dialogRef.afterClosed().subscribe(result => {
                observer.next(result);
            });
        });

    }

    addToken(req: HttpRequest<any>): HttpRequest<any> {

        let authReq = req;
        const token = this.tokenStorage.getToken();
        var self = this;
        if (token != null) {
            authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)});
        }
        
       return authReq;
    }
}



export const authInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];


