import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from './_services/token-storage.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';
import {NotificationsComponent} from './notifications/notifications.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  username: string;

  constructor(private tokenStorageService: TokenStorageService,  private translate: TranslateService,
              public notifications: NotificationsComponent) {

  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.username = user.username;
      if(this.tokenStorageService.getUser().language!=null){
        this.translate.use(this.tokenStorageService.getUser().language);
      }

    }
    else{
      this.translate.use(environment.defaultLanguage);
    }
  }

}
